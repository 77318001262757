@font-face {
  font-family: "C64 Pro Mono";
  src: local("C64 Pro Mono"), url(fonts/C64_Pro_Mono-STYLE.woff) format("woff");
}

@font-face {
  font-family: "Ubuntu Mono Bold";
  src: local("Ubuntu Mono Bold"), url(fonts/ubuntu-mono-v15-latin-ext_latin-700.woff) format("woff");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Ubuntu Mono Bold", monospace;
  box-sizing: border-box;
  color: white;
  font-size: 2;
  text-shadow:
    2px 0 4px #ffffff29,
    -2px 0 4px #ffffff29,
    5px 0 7px #ffffff29,
    -5px 0 7px #ffffff29,
    8px 0 12px #ffffff29,
    -8px 0 12px #ffffff29;
}
*::selection {
  background: red;
  color: white;
}
ol {
  padding-left: 3ch;
}
ul {
  list-style: none;
}
ul li:before {
  content: "- ";
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
html {
  background-color: black;
}
.width80 {
  width: 80ch;
  margin: 0 auto;
}
.width34 {
  width: 34ch;
  margin: 0 auto;
  text-align: left;
  display: "inline-block"
}
a {
  text-decoration: none;
}

/* ANSIfied PETSCII */
.p2a {
  font-family: monospace;
  text-shadow: none;
}

/* PETSCII */
.c64 {
  font-family: "C64 Pro Mono", monospace;
  line-height: 0.999;
  text-shadow: none;
}
.petsciiChar {
  display: inline-block;
  height: 1ch;
}
.fg-petmate-black { color: black }
.fg-petmate-white { color: white }
.fg-petmate-redDark { color: #924A40 }
.fg-petmate-cyan { color: #84C5CC }
.fg-petmate-magenta { color: #9351B6 }
.fg-petmate-greenDark { color: #72B14B }
.fg-petmate-blueDark { color: #483AA4 }
.fg-petmate-yellow { color: #D5DF7C }
.fg-petmate-brown { color: #99692D }
.fg-petmate-brownDark { color: #675201 }
.fg-petmate-red { color: #C08178 }
.fg-petmate-grayDark { color: #606060 }
.fg-petmate-gray { color: #8A8A8A }
.fg-petmate-green { color: #B2EC91 }
.fg-petmate-blue { color: #867ADE }
.fg-petmate-grayLight { color: #AEAEAE }
.bg-petmate-black { background-color: black }
.bg-petmate-white { background-color: white }
.bg-petmate-redDark { background-color: #924A40 }
.bg-petmate-cyan { background-color: #84C5CC }
.bg-petmate-magenta { background-color: #9351B6 }
.bg-petmate-greenDark { background-color: #72B14B }
.bg-petmate-blueDark { background-color: #483AA4 }
.bg-petmate-yellow { background-color: #D5DF7C }
.bg-petmate-brown { background-color: #99692D }
.bg-petmate-brownDark { background-color: #675201 }
.bg-petmate-red { background-color: #C08178 }
.bg-petmate-grayDark { background-color: #606060 }
.bg-petmate-gray { background-color: #8A8A8A }
.bg-petmate-green { background-color: #B2EC91 }
.bg-petmate-blue { background-color: #867ADE }
.bg-petmate-grayLight { background-color: #AEAEAE }

.fg-colodore-black { color: black }
.fg-colodore-white { color: white }
.fg-colodore-redDark { color: #813338 }
.fg-colodore-cyan { color: #75CEC8 }
.fg-colodore-magenta { color: #8E3C97 }
.fg-colodore-greenDark { color: #56AC4D }
.fg-colodore-blueDark { color: #2E2C9B }
.fg-colodore-yellow { color: #EDF171 }
.fg-colodore-brown { color: #8E5029 }
.fg-colodore-brownDark { color: #553800 }
.fg-colodore-red { color: #C46C71 }
.fg-colodore-grayDark { color: #4A4A4A }
.fg-colodore-gray { color: #7B7B7B }
.fg-colodore-green { color: #A9FF9F }
.fg-colodore-blue { color: #706DEB }
.fg-colodore-grayLight { color: #B2B2B2 }
.bg-colodore-black { background-color: black }
.bg-colodore-white { background-color: white }
.bg-colodore-redDark { background-color: #813338 }
.bg-colodore-cyan { background-color: #75CEC8 }
.bg-colodore-magenta { background-color: #8E3C97 }
.bg-colodore-greenDark { background-color: #56AC4D }
.bg-colodore-blueDark { background-color: #2E2C9B }
.bg-colodore-yellow { background-color: #EDF171 }
.bg-colodore-brown { background-color: #8E5029 }
.bg-colodore-brownDark { background-color: #553800 }
.bg-colodore-red { background-color: #C46C71 }
.bg-colodore-grayDark { background-color: #4A4A4A }
.bg-colodore-gray { background-color: #7B7B7B }
.bg-colodore-green { background-color: #A9FF9F }
.bg-colodore-blue { background-color: #706DEB }
.bg-colodore-grayLight { background-color: #B2B2B2 }

.ubuntuMono {
  font-family: "Ubuntu Mono Bold";
}
.clickable {
  cursor: pointer;
  color: red;
  background-color: black;
  display: inline-block;
  margin: -0.0157px;
  text-shadow:
    2px 0 4px #ff000029,
    -2px 0 4px #ff000029,
    5px 0 7px #ff000029,
    -5px 0 7px #ff000029,
    8px 0 12px #ff000029,
    -8px 0 12px #ff000029;
}
.strikethrough {
  text-decoration: line-through;
}
.clickable:hover {
  cursor: pointer;
  background-color: white;
  color: red;
}
.active {
  background-color: red;
  color: white;
}
.disabled {
  background-color: black;
  color: white;
}
.disabled:hover {
  cursor: text;
  background-color: white;
  color: black;
}
.right {
  float: right;
}
.left {
  float: left;
}
.inlineBlock {
  display: inline-block;
}
.center {
  text-align: center;
}
.frame {
  display: none;
}
.reversed {
  transform: rotate(180deg) scaleX(1);
}
input,
textarea {
  color: white;
  background-color: black;
  text-decoration: none;
  border-style: none;
  border-color: Transparent;
  overflow: hidden;
  outline: none;
  width: 75.99ch;
}

.imageSelector {
}

.imageDisplay {
  /* width: 500px;
  height: 500px;
  object-fit: cover;
  object-position: center; */
}

.image {
  /* width: 100%; */
  /* height: 100%; */
}

input::file-selector-button {
  margin: 0 1ch 0 0;
  padding: 0;
  border: 0;
  font-family: "Ubuntu Mono Bold", monospace;
  box-sizing: border-box;
  color: red;
  background-color: black;
  font-size: 2;
  text-shadow:
    2px 0 4px #ff000029,
    -2px 0 4px #ff000029,
    5px 0 7px #ff000029,
    -5px 0 7px #ff000029,
    8px 0 12px #ff000029,
    -8px 0 12px #ff000029;
}

.settingsBar {
  justify-content: space-between;
  display: flex;
}
